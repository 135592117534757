import { useRouter } from 'next/router';
import { useCallback } from 'react';

export enum Page {
  home = '/',
  cart = '/carrinho',
  service = '/servicos/:id',
  categoryRoot = '/categoria',
  category = '/categoria/:id',
  login = '/login',
  search = '/busca',
  checkoutPayment = '/checkout/pagamento',
  checkoutEntrega = '/checkout/entrega',
  checkoutReview = '/checkout/revisao',
  checkoutConfirmation = '/checkout/confirmacao',
  checkout = '/checkout',
  myOrders = '/v2/minha-conta/meus-pedidos',
  logout = '/logout',
  pedidos = '/v2/minha-conta/meus-pedidos',
  sobrePecaai = '/sobre-a-pecaai',
  ajuda = '/faq',
  perfil = '/perfil',
  updatePassword = '/atualizar-senha',
  updatePasswordDone = '/atualizar-senha/senha-alterada',
  privacyTerms = '/politica-de-privacidade',
  nfeXML = '/nfe/:id/:number/:series/xml',
  nfePDF = '/nfe/:id/:number/:series/pdf',
  backofficeNfeXML = '/nfe/:id/:orderId/xml',
  backofficeNfePDF = '/nfe/:id/:orderId/pdf',
  billing = '/cobrancas/:id',
  myAccountOrder = '/minha-conta/meus-pedidos/:id',
  product = '/produto/:id',
}

type QueryParam = {
  [key: string]: string;
};

export const linkToPage = (page: Page, args = {}, query?: QueryParam) => {
  let url = page.replace(/:([a-z]+)/gi, (_, b) => args[b]);
  if (query) url = `${url}?${new URLSearchParams(query).toString()}`;
  return url;
};

export const useNavigation = () => {
  const Router = useRouter();

  // Get the previous page navigated
  const getBackPage = useCallback(
    (page?: Page, fallback?: string) => {
      if (!process.browser) return;
      const track = JSON.parse(localStorage.getItem('previousUrl') || '[]');
      let last = track.find((url) => url !== Router.asPath);
      if (page) {
        const pageRegex = RegExp(page.replace(/:\w+/g, '(.*?)'));
        last = track.find((url) => pageRegex.test(url));
      }
      return last || fallback || '/';
    },
    [Router.asPath]
  );

  // Move user back
  const goBack = useCallback(
    (fallback?: string) => {
      if (!process.browser) return;
      Router.push(getBackPage(undefined, fallback));
    },
    [getBackPage]
  );

  // Move user back to a specific page from his navigation history
  // history is kept in localstorage and updated by an event from
  // router that can be checked on _app.ts
  const goBackToPage = useCallback(
    (page: Page, fallback?: string) => {
      if (!process.browser) return;
      Router.push(getBackPage(page, fallback));
    },
    [getBackPage]
  );

  // Move user to a specific route (string)
  const goTo = useCallback((page) => {
    if (typeof window === 'undefined') return;
    Router.push(page);
  }, []);

  const goToPage = useCallback((page: Page, args = {}, query?: QueryParam) => {
    if (!process.browser) return;
    Router.push(linkToPage(page, args, query));
  }, []);

  const getQueryParam = useCallback(
    (param: string) => {
      const value = Router.query?.[param] ?? Router.asPath.match(new RegExp(`[&?]${param}=(.*)(&|$)`))?.[1];
      if (value === null) return '';
      if (typeof value === 'string') return value;
      if (typeof value === 'object') return value[0];
      return '';
    },
    [Router.query]
  );

  return { goBack, getBackPage, goBackToPage, goTo, goToPage, getQueryParam, linkToPage, router: Router };
};

export const withPath = (Component) => {
  Component.getInitialProps = ({ asPath }) => {
    const path = asPath.replace(/\/(.*?)\?.*/, '$1');
    return { path };
  };

  return Component;
};
